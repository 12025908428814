<template>
    <section>
        <div class="d-flex mx-0 h-100 bg-white br-t-12">
            <div class="h-100 border-right" style="width:450px;">
                <div v-if="rutaCedis" :class="`row mx-0 ${rutaCedis ? 'border-bottom' : ''}`" style="height:44px;">
                    <el-tooltip v-if="rutaCedis && $can('tab2_ver_cedis_productos_inventario')" class="item" effect="light" content="Lista de productos" placement="bottom">
                        <div class="col px-1 text-center d-middle-center f-14 cr-pointer" :class="producto ?'tab-active f-600':'text-general2'" @click="listado()">
                            <i class="icon-package-variant-closed f-22" /> <span v-show="producto">Productos</span>
                        </div>
                    </el-tooltip>
                    <el-tooltip v-if="rutaCedis && $can('tab2_ver_cedis_productos_inventario')" class="item" effect="light" content="Inventario de productos" placement="bottom">
                        <div class="col px-1 text-center d-middle-center f-14 cr-pointer" :class="inventario?'tab-active f-600':'text-general2'" @click="verInventario()">
                            <i class="icon-format-list-checks f-22" /> <span v-show="inventario">Inventario</span>
                        </div>
                    </el-tooltip>
                    <el-tooltip v-if="rutaCedis && $can('tab2_ver_cedis_productos_inventario')" class="item" effect="light" content="Productos con stock mínimo" placement="bottom">
                        <div class="col px-1 text-center d-middle-center pr-0  f-14 cr-pointer" :class="stockMin?'tab-active f-600':'text-general2'" @click="stock_bajo()">
                            <i class="icon-package-variant f-22" /> <span v-show="stockMin">Stock mínimo</span>
                        </div>
                    </el-tooltip>
                    <el-tooltip v-if="rutaCedis && $can('tab2_ver_cedis_productos_inventario')" class="item" effect="light" content="Cambio de precio" placement="bottom">
                        <div class="col px-1 text-center d-middle-center pr-0  f-14 cr-pointer" :class="cambioPrecio?'tab-active f-600':'text-general2'" @click="showCambioPrecio()">
                            <i class="icon-currency-usd-circle f-20" /> <span v-show="cambioPrecio">Cambio precio</span>
                        </div>
                    </el-tooltip>
                    <el-tooltip v-if="rutaCedis && $can('tab2_ver_cedis_productos_inventario')" class="item" effect="light" content="Vencimiento de productos" placement="bottom">
                        <div class="col px-1 text-center d-middle-center f-14 cr-pointer" :class="vencimiento ?'tab-active f-600':'text-general2'" @click="verVencimiento()">
                            <i class="icon-calendar-clock f-20" /> <span v-show="vencimiento">Vencimiento</span>
                        </div>
                    </el-tooltip>
                </div>
                <!-- Categorias -->
                <div v-if="!inventario && !stockMin && !cambioPrecio && !vencimiento" class="d-flex mx-0 py-2 px-2 border-bottom justify-content-between align-items-center">
                    <div class="col px-0 my-auto">
                        <el-input v-model="buscar" placeholder="Buscar producto" size="small" class="br-20" />
                    </div>
                    <el-tooltip v-if="(id_cedi_ruta || (cedisNavbar.length == 1)) && createDisponible && $can('botones_productos_gestionar_producto')" class="item" effect="light" content="Añadir producto" placement="bottom">
                        <div class="btn-anadir bg-general  cr-pointer mx-2 d-middle-center br-8" @click="crearProducto">
                            <i class="icon-package-add f-18 text-white" />
                        </div>
                    </el-tooltip>
                    <el-popover ref="opcionesProductos" v-model="visibleOptions" placement="bottom" effect="light" popper-class="br-8" trigger="manual">
                        <div
                        v-if="(cedisNavbar.length != 1 && $route.name == 'admin.productos') && $can('boton_productos_fusionar_productos')"
                        class="row mx-0 options-popover f-14 py-2 px-3 cr-pointer"
                        @click="fusionarProductos"
                        >
                            Fusionar productos
                        </div>
                        <div class="row mx-0 options-popover f-14 py-2 px-3 cr-pointer" @click="importarImagenes">
                            Importar imagenes
                        </div>
                        <button slot="reference" type="button" class="btn-action border ml-2" @click="abrirOpciones">
                            <i class="icon-dots-vertical f-18" />
                        </button>
                    </el-popover>
                </div>
                <!-- Categorias -->
                <div
                class="menu-prinicipal col overflow-auto custom-scroll px-0"
                :class="rutaCedis ? (!inventario && !stockMin && !cambioPrecio ? 'height-cedis' : 'height-cedis-wt') : 'height-productos'"
                >
                    <div v-show="inventario" class="row mx-0 border-bottom p-1 py-2">
                        <div class="col my-auto text-general f-600">
                            Inventario de productos
                        </div>
                    </div>
                    <content-loader v-show="loading" />
                    <div
                    v-for="(c, idx) in categorias" :key="idx"
                    class="row mx-0 d-middle border-bottom cr-pointer"
                    style="height:54px;"
                    @click="id_categoria = c.id, activo=c.id"
                    >
                        <div :class="`mx-2 px-1 h-80 br-5 ${activo==c.id?'bg-general':''}`" />
                        <p :class="`col py-3 px-0 tres-puntos f-15 f-500 ${activo==c.id?'text-general':'text-general2'}`">{{ c.nombre }}</p>
                        <div class="col-auto d-middle px-0">
                            <div :class="`br-10 px-2 text-center f-17 my-auto ${activo==c.id?'bg-general3 text-white':'bg-whitesmoke'}`" style="min-width:40px;">
                                <span>{{ c.cant_productos }}</span>
                            </div>
                            <i :class="`icon-angle-right f-18 ${activo==c.id?'text-general':''}`" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="px-0 h-100 border-left menu-prinicipal" style="width: calc(100vw - 492px);">
                <listado-productos v-if="producto || stockMin || vencimiento" :categoria="id_categoria" :caso="caso" />
                <catalogo v-else-if="inventario" :categoria="id_categoria" />
                <cambio-precio v-else-if="cambioPrecio" :categoria="id_categoria" />
            </div>
        </div>
        <modalCrearProducto ref="modalCrearProducto" :con-cedis-producto="conCedisProducto" @update="actualizar_listado" />
        <modal-descargar-catalogo ref="modalDescargarCatalogo" />
        <modalFusionarProducto ref="modalFusionarProducto" @update="update_fusionar" />
        <modal-importar-imagenes ref="modalImportarImagenes" />
        <modal-importar-productos-masivo ref="modalImportarProductosMasivo" />
        <modal-importar-actualizar-datos ref="modalImportarActualizarDatos" />
        <modal-subir-stock ref="modalImportarStock" />
        <modal-opciones-productos
        ref="modalOpcionesProductos"
        @importProductos="$refs.modalImportarProductosMasivo.setImportarProdutos()"
        @importUpdateDataProducts="$refs.modalImportarActualizarDatos.toggle()"
        @dowloadCatalogo="descargar"
        @uploadInventario="uploadInventario"
        />
    </section>
</template>

<script>
import Productos from "~/services/productos/productos"
import ProductosAdmin from "~/services/productos/productosAdmin"
import {mapGetters} from 'vuex'
export default {
    metaInfo: () =>({ title: 'Productos'}),
    components: {
        listadoProductos: () => import('./listado-productos'),
        catalogo: () => import('../catalogo'),
        cambioPrecio: () => import('./components/cambioPrecio'),
        modalCrearProducto: () => import('../partials/modalCrearProducto'),
        modalFusionarProducto:() => import('../partials/modalFusionarProductos'),
        modalDescargarCatalogo:() => import('~/pages/configurar/admin/partials/modalDescargarCatalogo'),
        modalImportarImagenes: () => import('../partials/modaImportarImagenes'),
        modalOpcionesProductos: () => import('../partials/modalOpcionesProducto'),
        modalImportarProductosMasivo: () => import('../partials/modalImportarProductosMasivo'),
        modalImportarActualizarDatos: () => import('../partials/modalImportarActualizarDatos'),
        modalSubirStock: () => import('~/pages/cedis/partials/modalImportarStock')
    },
    middleware:['auth'],

    data(){
        return {
            conCedisProducto: false,
            producto: false,
            stockMin: false,
            inventario: false,
            cambioPrecio: false,
            vencimiento: false,
            id_cedi_ruta: parseInt(this.$route.params.id_cedis),
            activo: -1,
            visibleOptions: false,
            id_categoria: null,
            categorias:[],
            loading:false,
            caso:7, // 7=> para listado de productos de normal, 8 => listado de producto con stock bajo,
            categorias_prueba:[
                {
                    nombre: 'Este es una categoria de prueba',
                    cant_productos: 200000000,
                }
            ],
        }
    },
    computed:{
        ...mapGetters({
            query: 'productos/productos/query',
            cedisNavbar:'cedis/cedisNavbar',
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
            info_cedis:'cedis/info_cedis',
        }),
        buscar: {
            // getter
            get: function(){
                return this.query
            },
            // setter
            set: function(newValue){
                this.$store.commit('productos/productos/set_query',newValue)
                this.id_categoria = null
                this.activo = -1
                if(newValue.length > 2){
                    const Search = () => {
                        this.listar()
                    }
                    this.delay(Search)

                }
                if(newValue.length === 0){
                    const Search = () => {
                        this.listar()
                    }
                    this.delay(Search)
                }
            }
        },
        tipo(){
            let caso = 0
            switch (this.$usuario.rol_nombre){
            case 'tendero':
                caso = 4
                break;
            case 'admin':
                caso = 1
                break;

            default:
                break;
            }
            return caso
        },
        id_tienda(){
            let id_tienda = null
            if (this.tipo == 4){
                id_tienda = this.$usuario.tienda.id
            }

            return id_tienda
        },
        cedis(){
            let id_cedis = this.cedisNavbar.length != 1 ? 0 : this.cedisNavbar[0].id
            if (this.rutaCedis){
                id_cedis = this.$route.params.id_cedis
            }

            return id_cedis
        },
        rutaCedis(){
            return this.$route.name == 'admin.cedis.productos' || this.$route.name == 'tendero.productos'
        },
        createDisponible(){
            return this.info_cedis.id != null && this.info_cedis.id != 0 && this.info_cedis.id_cedis_catalogo === null
        }
    },
    watch:{
        id_cedis(val){
            if(val != null){
                this.listar()
            }
        }
    },
    mounted(){

        this.buscar= '' // lanza el metodo de  lista_categorias por la computada
        this.producto = true
        this.$store.commit('productos/productos/vaciar_productos')
        this.$store.commit('productos/productos/set_id_producto', null)
        this.$store.commit('productos/productos/set_is_kit', false)
        if(!isNaN(this.id_cedi_ruta)){
            //console.log('acaaaaaaaaaa',this.id_cedi_ruta);
            if(this.id_cedis != this.id_cedi_ruta){
                this.$store.commit('cedis/set_id_cedis', this.id_cedi_ruta)
            }
        }
    },
    destroyed(){
        this.$store.commit('productos/productos/vaciar_productos')
    },
    methods: {
        crearProducto(){
            this.conCedisProducto = (this.cedis)!==0
            this.$refs.modalCrearProducto.toggle()
        },
        abrirOpciones(){
            if(this.$route.name == 'admin.cedis.productos'){
                this.$refs.modalOpcionesProductos.toggle()
            } else{
                this.visibleOptions = !this.visibleOptions;
            }
        },
        importarImagenes(){
            this.$refs.opcionesProductos.doToggle()
            this.$refs.modalImportarImagenes.toggle();
        },
        fusionarProductos(){
            this.$refs.modalFusionarProducto.toggle();
        },
        listar(){
            if(this.vencimiento){
                this.lista_categorias_vencimiento()
            } else {
                this.lista_categorias()
            }
        },
        async showCambioPrecio(){
            try {
                this.cambioPrecio = true
                this.inventario = false
                this.stockMin = false
                this.producto = false
                this.vencimiento = false
                this.loading = true
                let id_cedis = this.id_cedi_ruta
                const {data} = await ProductosAdmin.historialCambioPrecio(id_cedis)
                this.categorias = data.categorias

            } catch (error){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        async lista_categorias(){
            try {
                this.categorias = []
                this.loading = true
                let params = {
                    query:this.query,
                    id_cedis: this.cedis,
                    id_moneda:this.id_moneda
                }
                const {data} = await ProductosAdmin.categorias(params)
                if(this.rutaCedis){
                    this.caso = 1 //cedis
                }else{
                    this.caso = 2 //productos
                }
                this.categorias = data.categorias
                if(this.inventario){

                    let i = this.categorias.findIndex(el => el.id == -1)
                    this.categorias.splice(i,1)

                }
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        async lista_categorias_vencimiento(){
            try {
                this.categorias = []
                this.loading = true
                let params = {
                    query:this.query,
                    id_cedis: this.cedis,
                    id_moneda:this.id_moneda,
                    vencimiento: 1
                }
                const {data} = await ProductosAdmin.categorias(params)
                if(this.rutaCedis){
                    this.caso = 8 //cedis
                }
                this.categorias = data.categorias
                if(this.inventario){

                    let i = this.categorias.findIndex(el => el.id == -1)
                    this.categorias.splice(i,1)

                }
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        verInventario(){
            this.lista_categorias()
            this.producto = false
            this.stockMin = false
            this.id_categoria = null
            this.inventario = !this.inventario
            this.vencimiento = false
        },
        listado(){
            this.lista_categorias()
            this.producto = true
            this.inventario = false
            this.stockMin = false
            this.cambioPrecio = false
            this.vencimiento = false
        },
        verVencimiento(){
            this.categorias = null
            this.lista_categorias_vencimiento()
            this.producto = false
            this.inventario = false
            this.stockMin = false
            this.cambioPrecio = false
            this.vencimiento = true
        },
        update_fusionar(){
            this.id_categoria= null
            this.lista_categorias()

        },
        actualizar_listado({categorias,producto}){
            let actual = categorias.find(o=>o.id === this.id_categoria)

            if(actual !== undefined){
                this.$store.commit('productos/productos/push_producto_nuevo', producto)
            }else{
                this.lista_categorias()
            }
        },
        async stock_bajo(){
            try {
                this.id_categoria = null // hacemos null para que se limpie el array de productos
                this.activo = -1 // dejamos -1 para que se quite el seleccionado de la categoria
                this.stockMin = !this.stockMin // cambiamos el estado para saber en  cual tab estamos
                this.producto = false
                this.cambioPrecio = false
                this.inventario = false
                if(this.stockMin){
                    let params = {
                        id_categoria: this.categoria,
                        id_cedis: this.cedis,
                        id_moneda: this.id_moneda,
                        id_tienda: this.id_tienda,
                        caso:this.tipo
                    }
                    this.loading = true

                    const {data} = await Productos.categorias_bajas_stock(params)
                    this.caso = 6
                    this.categorias = data

                }else{
                    this.lista_categorias()

                }
            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        descargar(){
            let datos = {
                cedis: this.cedis != 0,
                id_cedis: this.cedis == 0 ? null : this.cedis,
                leechero: false,
                id_leechero: null
            }
            this.$refs.modalDescargarCatalogo.toggle(datos);
        },
        uploadInventario(){
            this.$refs.modalImportarStock.toggle();
        },
    }
}
</script>

<style lang="scss" scoped>
.menu-prinicipal{ background: #fff; }
.height-productos{
    //width:360px;
    height: calc(100vh - 152px);
}
.height-cedis{
    height: calc(100vh - 287px);
}
.height-cedis-wt{
    height:calc(100vh - 239px);
}
.border-dark{
    border: 1px solid var(--text-general);

}

.options-popover{
    &:hover{
        background-color: #F6F9FB;
    }
}

.br-12{
    border-radius: 12px !important;
}
a{
    text-decoration: none !important;
    &:hover{
        color: var(--text-general) !important;
    }
}
.btn-catalogo{
    width: 32px;
    height: 32px;
    color: var(--text-general) !important;
    background-image: linear-gradient(to bottom, #FFFFFF, #F3F5F7);
}
.btn-anadir{
    width: 32px;
    height: 32px;
    color: #ffffff;
}
.tab-active{
    border-bottom: 3px solid var(--text-general) !important;
    color: var(--text-general) !important;
}
</style>
